<template>
  <div>
    <b-card>
      <h1>管理画面</h1>
    </b-card>
  </div>
</template>

<script>

import { EventBus } from '@/utils/event-bus';

export default {
  title: "admin_manager",
  name: "Index",
  async mounted() {
    const result = await this.getAllRegisterRequest();

    if(result) {
        EventBus.$emit('loadNumberRequests', result.length)
    }
  },
  methods: {
    async getAllRegisterRequest() {
      const result = await this.$store
        .dispatch("register_player/getRegisterPlayersAll")
        .catch((err) => {
          console.log(err);
        });
      if (result) {
        return result;
      }

      return null;
    },
  },
};
</script>